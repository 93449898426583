<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <ShopsAndSites
      v-if="tress.length != 0"
      style="margin-right: 10px; float: left"
      :list="tress"
      @getShopId="getID"
    ></ShopsAndSites>
    <TimeQuantum
      style="float: left; margin-right: 5px"
      @selectFinish="topTime"
      section="自定义"
      @sectionFinish="sectionSure"
    ></TimeQuantum>
    <div style="float: left; margin-right: 5px">
      <el-select
        v-model="pageInfo.orderProgress"
        placeholder="选择订单类型"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in statusList"
          :key="item.value"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div style="float: left; margin-right: 5px">
      <el-select
        v-model="pageInfo.shipmentType"
        placeholder="发货类型"
        clearable
        @change = "search"
      >
        <el-option
          v-for="item in shipmentList"
          :key="item.value"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <el-input
      clearable
      style="width: 500px; margin-right: 10px"
      placeholder="请输入"
      v-model="pageInfo.keyword"
      class="input-with-select"
      @keyup.enter.native = "search"
    >
    </el-input>
    <el-button @click="search">查询</el-button>
    <el-button @click="toGet">同步订单</el-button>
    <!-- 表格 -->
    <el-table
      border
      :data="tableData"
      @sort-change="sortTable"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 40px"
      header-cell-style="background:#FAFAFA;text-align:center;font-size:14px"
    >
      <el-table-column
        width="300px"
        label="订单号"
        align="center"
        prop="orderNumber"
        fixed
      >
      </el-table-column>
      <el-table-column
        label="商品信息"
        align="center"
        prop="commoditySku"
        fixed
      >
      </el-table-column
      ><el-table-column label="商品数量" align="center" prop="counts" fixed>
      </el-table-column
      ><el-table-column label="销售额" align="center" prop="salePrice" fixed>
      </el-table-column
      ><el-table-column label="订单金额" align="center" prop="goodsPrice" fixed>
      </el-table-column
      ><el-table-column
        label="订单状态"
        align="center"
        prop="orderProgress"
        fixed
      >
      </el-table-column
      ><el-table-column
        label="发货类型"
        align="center"
        prop="shipmentType"
        fixed
      >
      </el-table-column
      ><el-table-column label="买家姓名" align="center" prop="orderName" fixed>
      </el-table-column
      ><el-table-column
        width="400px"
        label="买家邮箱"
        align="center"
        prop="orderEmailAddress"
        fixed
      >
      </el-table-column>
      <el-table-column width="50px" label="备注" align="center">
        <template slot-scope="scope">
          <el-link
            style="margin-right: 40px"
            :underline="false"
            type="primary"
            icon="el-icon-edit"
            @click="asyncEdit(scope.row.id)"
          ></el-link>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="60px">
        <template slot-scope="scope">
          <el-link
            style="margin-right: 40px"
            :underline="false"
            type="primary"
            icon="el-icon-document"
            @click="getDetail(scope.row.id)"
          ></el-link>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center; margin: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[15, 20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 操作弹框 -->
    <el-dialog
      width="1200px"
      title="货件确认"
      :visible.sync="innerVisible"
      append-to-body
    >
      <h3>收货人信息</h3>
      <div class="aaa" style="margin-top: 20px; margin-left: 80px">
        <div class="fn">
          <div class="lable">收货人邮编</div>
          <div class="value">{{ myList.orderPostCode }}</div>
        </div>
        <div class="fn">
          <div class="lable">收货人都道</div>
          <div class="value">{{ myList.orderPrefecture }}</div>
        </div>
        <div class="fn">
          <div class="lable">收货人郡市区</div>
          <div class="value">{{ myList.orderCity }}</div>
        </div>
        <div class="fn">
          <div class="lable">收货人住所</div>
          <div class="value">{{ myList.orderSubAddres }}</div>
        </div>
        <div class="fn">
          <div class="lable">收货人姓名</div>
          <div class="value">{{ myList.orderName }}</div>
        </div>
        <div class="fn">
          <div class="lable">收货人电话</div>
          <div class="value">{{ myList.orderPhoneNumber }}</div>
        </div>
      </div>

      <h3 style="margin-top: 14%">配货信息</h3>
      <el-table
        border
        :data="tableDatas"
        @sort-change="sortTable"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-cell-style="background:#FAFAFA;text-align:center;font-size:14px"
      >
        <el-table-column label="商品名" align="center" prop="itemName" fixed>
        </el-table-column
        ><el-table-column label="商品id" align="center" prop="itemId" fixed>
        </el-table-column
        ><el-table-column
          label="商品番号"
          align="center"
          prop="itemNumber"
          fixed
        >
        </el-table-column
        ><el-table-column
          label="商品管理番号"
          align="center"
          prop="manageNumber"
          fixed
        >
        </el-table-column>
        <el-table-column label="单价" align="center" prop="price" fixed>
        </el-table-column>
        <el-table-column label="个数" align="center" prop="units" fixed>
        </el-table-column>
        <el-table-column
          label="以下"
          align="center"
          prop="DestinationFulfillmentCenterId"
          fixed
        >
        </el-table-column>
      </el-table>
      <!-- <div style="text-align: right; margin-top: 30px">
        <el-button @click="innerVisible = false">取消</el-button>
        <el-button type="primary" @click="shenbaoConfirm">确认</el-button>
      </div> -->
    </el-dialog>

    <!-- 同步订单按钮 -->
    <el-dialog
      width="600px"
      :title="title"
      :visible.sync="addVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        :model="shipFromAddress"
        class="demo-form-inline"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="店铺">
          <ShopsAndSites
            style="margin-right: 10px"
            :list="tress"
            @getShopId="getID"
          ></ShopsAndSites>
        </el-form-item>
        <el-form-item label="选择时间">
          <TimeQuantum
            style="float: left; margin-right: 5px"
            @selectFinish="topTime"
            @sectionFinish="sectionSure"
            section="自定义"
          ></TimeQuantum>
        </el-form-item>
        <el-form-item label="时间类型">
          <el-select v-model="list.dateType" placeholder="请选择时间类型">
            <el-option
              v-for="item in typeLists"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div style="text-align: right; margin-top: 30px">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="ordersync">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 备注 -->
    <el-dialog
      width="500px"
      title="订单备注"
      :visible.sync="remark"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        :model="shipFromAddress"
        class="demo-form-inline"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="订单号码">
          <el-input
            disabled
            placeholder=""
            v-model="orderNumber"
            style="width: 225px"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="测评订单">
          <el-select v-model="item.isTest" placeholder="">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单备注">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="订单备注"
            v-model="item.systemMemo"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div style="text-align: right; margin-top: 30px">
        <el-button @click="remark = false">取 消</el-button>
        <el-button type="primary" @click="remarkConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ShopsAndSites from "@/components/ShopsAndSites.vue";
import TimeQuantum from "@/components/TimeQuantum";
import {
  addInit,
  listPage,
  PageInit,
  detail,
  ordersyncInit,
  setOrderSysTips,
  systemMemoInit,
  ordersync,
} from "@/api/orderCenter/letianOrder.js";
export default {
  components: {
    ShopsAndSites,
    TimeQuantum,
  },
  props: ["flag"],
  data() {
    return {
      loading:true,
      isTest: "",
      typeList: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      typeLists: [
        {
          value: "1",
          label: "创建时间",
        },
        {
          value: "0",
          label: "付款时间",
        },
      ],
      orderNumber: "",
      total: "",
      remark: false,
      addVisible: false,
      innerVisible: false,
      tableDatas: [],
      pageInfo: {
        marketPlaceId: "",
        shopId: "",
      },
      keyword: "",
      find: {
        shipment: "",
        status: "",
      },
      quantity: "",
      tress: [],
      tableData: [],
      // ooo : 4,
      pageInfo: {
        current: "1",
        pageSize: "15",
        shopId: "",
        dateStart: "",
        dateEnd: "",
        shipmentType: "",
        dateType: "",
        orderProgress: "",
        isSend: "",
        keyword: "",
      },
      myList: {},
      shipmentList: [],
      statusList: [],
      item: {
        id: "",
        systemMemo: "",
        isTest: "",
      },
      list: {
        dateStart: "",
        dateEnd: "",
        shopId: "",
        dateType: "",
      },
    };
  },
  mounted() {},
  created() {
    this.fla = 2;

    this.getData();
    this.getPageInit();
    this.getData2();
  },
  methods: {
    //同步确认
    ordersync() {
      ordersync(this.list).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.addVisible = false;
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    //编辑
    asyncEdit(id) {
      this.item.id = id;
      this.remark = true;
      systemMemoInit(id).then((res) => {
        this.remark = true;
        this.loading = false;
        if (res.data.code == 200) {
          this.orderNumber = res.data.data.orderNo;
          this.isTest = res.data.data.isTest == 0 ? "否" : "是";
          this.item.systemMemo = res.data.data.systemMemo;
        } else {
          this.dialogLoading = false;
          this.remark = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    //编辑确认
    remarkConfirm() {
      setOrderSysTips(this.item).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.remark = false;
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    //日期选择
    topTime(val) {
      this.pageInfo.dateStart = val.value[0];
      this.pageInfo.dateEnd = val.value[1];
      this.list.dateStart = val.value[0];
      this.list.dateEnd = val.value[1];
    },
    //分页
    handleCurrentChange(val) {
      this.pageInfo.current = val;
      this.getData2();
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getData2();
    },
    //操作
    getDetail(id) {
      this.loading = true;
      detail(id).then((res) => {
        this.innerVisible = true;
        this.loading = false;
        if (res.data.code == 200) {
          this.tableDatas =
            res.data.data.packageResponseList[0].productResponseList;
          this.myList = res.data.data.packageResponseList[0];
        } else {
          this.dialogLoading = false;
          this.innerVisible = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    //同步订单
    toGet() {
      this.loading = true;
      this.addVisible = true;
    },
    detail() {
      this.innerVisible = true;
    },
    async getData() {
      try {
        const res = await addInit();
        if ((Object.is(res.code), 200)) {
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getPageInit() {
      this.loading=true
      PageInit().then((res) => {
        this.loading=false
        this.shipmentList = res.data.data.shipmentList;
        this.statusList = res.data.data.statusList;
        this.tress = res.data.data.shopList;
        this.tress.forEach((item) => {
          item.marketPlaceTree.forEach((i) => {
            if (i.isSelected == 1) {
              this.pageInfo.shopId = item.id;
              this.pageInfo.marketPlaceId = i.id;
            }
          });
        });
        // this.getData2();
      });
    },
    getID(shopID, marketPlaceId) {
      this.pageInfo.shopId = shopID;
      this.pageInfo.marketPlaceId = marketPlaceId;
      this.list.shopId = shopID;
    },
    getData2() {
      listPage(this.pageInfo).then((res) => {
        this.loading = false;
        this.tableData = res.data.values;
        this.tableData.map(function (val) {
          if (val.shipmentType === 0) {
            val.shipmentType = "普通";
          } else {
            val.shipmentType = "次日达";
          }
        });
        this.tableData.map(function (val) {
          if (val.orderProgress == 900) {
            val.orderProgress = "キャンセル確定";
          } else if (val.orderProgress == 100) {
            val.orderProgress = "注文確認待ち";
          } else if (val.orderProgress == 200) {
            val.orderProgress = "楽天処理中";
          } else if (val.orderProgress == 300) {
            val.orderProgress = "発送待ち";
          } else if (val.orderProgress == 400) {
            val.orderProgress = "変更確定待ち";
          } else if (val.orderProgress == 500) {
            val.orderProgress = "発送済";
          } else if (val.orderProgress == 600) {
            val.orderProgress = "支払手続き中";
          } else if (val.orderProgress == 700) {
            val.orderProgress = "支払手続き済";
          } else if (val.orderProgress == 800) {
            val.orderProgress = "キャンセル確定待ち";
          }
        });
        this.total = res.data.pageInfo.total;
      });
    },
    search() {
      this.getData2();
      this.pageInfo.current = 1;
    },
  },
};
</script>


<style scoped lang='scss'>
.picture {
  width: 160px;
  height: 160px;
  font-size: 8px;
  color: #909399;
  text-align: center;
  line-height: 160px;
  margin-right: 10px;
  span {
    display: block;
    background: #ebeef5;
    width: 100%;
    height: 100%;
  }
}
.infoText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 25px;
  margin: 15px 0 20px 0;
}
.suspend {
  p {
    span {
      display: inline-block;
      width: 180px;
      margin-right: 10px;
      font-size: 13px;
    }
  }
}
.fn {
  width: 40%;
  height: 40px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 2%;
  text-align: center;
}
.lable {
  width: 30%;
  height: 38px;
  float: left;
  line-height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 4px 0px 0px 4px;
}
.value {
  width: 65%;
  height: 40px;
  float: left;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: left;
  padding-left: 50px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-left: none;
  color: #666666ff;
  border-radius: 0px 4px 4px 0px;
}
</style>